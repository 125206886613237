import React, { useState } from "react";
import axios from "axios";
import './contractScanner.css';
// Importing FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShieldAlt, faChartLine, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FaTelegram, FaTwitter } from 'react-icons/fa';

const ContractScanner = () => {
  const [contractAddress, setContractAddress] = useState("");
  const [threats, setThreats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [holders, setHolders] = useState(null);
  const [dev, setDev] = useState(null);
  const [stability, setStability] = useState(null);
  const [error, setError] = useState("");
  const [expandedThreats, setExpandedThreats] = useState({});
  const [tokenName, setTokenName] = useState(""); // State to store tokenName
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:5000' : 'https://www.trendscout.club';

  const handleInputChange = (e) => {
    setContractAddress(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setResults(null);
    setThreats(null);
    setHolders(null);
    setDev(null); 
    setStability(null); 
    setTokenName(""); 
    setLoading(true);
  
    try {
      const response = await axios.post(`${baseUrl}/scan-contract`, { contractAddress });
      setResults(response.data.verificationData);
      console.log(response.data.categorizedThreats)
  
      // Ensure 'Holders' is an array before setting it to state
      const holdersData = Array.isArray(response.data.verificationData.Holders)
        ? response.data.verificationData.Holders : [];
      setHolders(holdersData);
      setDev(response.data.verificationData.DevDetails);
      setStability(response.data.verificationData.Stability)
      setThreats(response.data.categorizedThreats);
      setTokenName(response.data.verificationData.TokenName || "Unknown Token"); // Assuming tokenName is in the response
    } catch (err) {
      setError("Error fetching contract details. Please ensure the address is correct and try again.");
    } finally {
      setLoading(false);
    }
  };

  const toggleThreatExpansion = (level) => {
    setExpandedThreats((prev) => ({
      ...prev,
      [level]: !prev[level],
    }));
  };

  const renderThreats = () => {
    return Object.entries(threats).map(([level, items]) => {
      const isExpanded = expandedThreats[level];
      const hasReasons = items.some((item) => item.reasons && item.reasons.length > 0);
  
      return (
        <div key={level} className={`threat-level ${level.toLowerCase()}`}>
          <h4 onClick={() => toggleThreatExpansion(level)} className="threat-header">
            {level}
            <FontAwesomeIcon
              icon={hasReasons ? faExclamationTriangle : faShieldAlt}
              style={{
                marginLeft: "10px",
                color: hasReasons ? "red" : "green", // Red for threats with reasons, green otherwise
              }}
            />
            <FontAwesomeIcon
              icon={isExpanded ? faMinus : faPlus}
              style={{ marginLeft: "10px", cursor: "pointer" }}
            />
          </h4>
          {isExpanded && (
            <ul>
              {items.length < 1 ? (
                <li>NONE</li>
              ) : (
                items.map((item, index) => (
                  <li key={index}>
                    <strong>{item.threat}</strong>
                    {item.reasons && (
                      <ul>
                        {item.reasons.map((reason, reasonIndex) => (
                          <li key={reasonIndex}>{reason}</li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))
              )}
            </ul>
          )}
        </div>
      );
    });
  };
  
  


  // Render Social Links with Icons only
  const renderSocialLinks2 = (socialLinks, contractAddress) => {
    const dextoolsLink = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`; // Adjust URL if necessary
    
    return (
      <>
        {Object.entries(socialLinks).map(([platform, link]) => {
          if (link && link !== "N/A") {
            let icon;
            switch (platform) {
              case 'telegram':
                icon = <FaTelegram />;
                break;
              case 'twitter':
                icon = <FaTwitter />;
                break;
              default:
                icon = null;
            }
  
            return (
              <div key={platform} className="social-link">
                <a href={link} target="_blank" rel="noopener noreferrer" className="social-icon-link">
                  {icon}
                </a>
              </div>
            );
          }
          return null; // Skip invalid links
        })}
  
        {/* Chart Icon */}
        <div className="social-link">
          <a href={dextoolsLink} target="_blank" rel="noopener noreferrer" className="social-icon-link">
            <FontAwesomeIcon icon={faChartLine} /> {/* Chart icon */}
          </a>
        </div>
      </>
    );
  };
  

  return (
    <div className="contractScanMain">
      <div className="container">
        <h2>Ethereum Contract Scanner</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter contract address"
            value={contractAddress}
            onChange={handleInputChange}
            className="input-field"
          />
          <button
            type="submit"
            disabled={loading || !contractAddress}
            className="submit-button"
          >
            {loading ? "Scanning..." : "Scan Contract"}
          </button>
        </form>

        {error && <p className="error">{error}</p>}

        {/* Display the token name after scanning */}
        {tokenName && !loading && (
          <div className="tokennamecontainer">
          <p className="token-name">{tokenName}</p> 
          </div>
        )}

          {results && (results.Telegram || results.Twitter) && (
            <div className="social-links">
              {renderSocialLinks2(
                {
                  telegram: results.Telegram,
                  twitter: results.Twitter,
                },
                contractAddress // Pass the contract address for the Dextools chart link
              )}
            </div>
          )}

                    {stability && (
                      <div className="results">
                        <h3>Stability Range</h3>
                        <div className="threat-level">
                          <div className="holder-address">
                            {stability === 1000 
                              ? <strong>Token is extremely quiet 🥱💤</strong> 
                              : <span>Short term, token trading within a <strong>{stability}% range</strong></span>}
                          </div>
                        </div>
                      </div>
                    )}



              {threats && (
                <div className="results">
                  <h3>Threat Levels</h3>
                  {renderThreats()}
                </div>
              )}


                  {dev && (
                    <div className="results">
                      <h3>Deployer Details</h3>
                      <div className="threat-level">
                        <div className="holder-address">
                        <strong>Deployer: </strong> {dev.devAddress} - {(parseFloat(dev.devHoldings)*100).toFixed(2)}%
                        </div>
                      </div>
                    </div>
                  )}

          
              {holders && Array.isArray(holders) && holders.length > 0 && (
                <div className="threat-level">
                  <h3>Holder Details</h3>
                  {holders.map((holder, index) => {
                    const percent = parseFloat(holder.percent).toFixed(2);
                    const isRedFlag = parseFloat(holder.percent) > 3;

                    return (
                      <div key={index} className="holder-item">
                        <div className="holder-address">{holder.address}</div>
                        <div className="holder-percent">
                          {percent}%
                          {isRedFlag && (
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              style={{ marginLeft: '5px', color: 'red' }} // Red flag icon
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}


        {results && (
          <div className="results">
            <h3>Raw Data</h3>
            <pre>{JSON.stringify(results, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContractScanner;
