import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import HomeMain from './home/homeMain';
import AllTokenData from './home/allTokensData';
import io from 'socket.io-client';
import PerformanceSim from './home/performanceSim'; 
import Prediction from './home/prediction';
import FiveDayTokens from './home/fiveDayTokens';
import HistoryTokens from './home/historyTokens';
import HistoryData from './home/historyData';
import LoginSignup from './login';
import Header from './header';
import { AuthProvider } from './authContext'; 
import StoredTokens from './home/storedtokens';
import StoredData from './home/storedData';
import ProtectedRoute from './protectedRoute';
import PerformanceSimAll from './home/performanceAll';
import ContractScanner from './home/contractScanner';
import ContractScannerAuto from './home/contractScannerAuto';

const isLocal = window.location.hostname === 'localhost';
const baseUrl = isLocal ? 'http://localhost:3000' : 'https://www.trendscout.club';
axios.defaults.baseURL = baseUrl;

// Initialize Socket.IO
const socket = io(baseUrl);

function App() {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [showPutNotification, setShowPutNotification] = useState(false);
  const [putNotificationMessage, setPutNotificationMessage] = useState('');
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [soundPlayedNew, setSoundPlayedNew] = useState(false);
  const [lastMoonshot, setLastMoonshot] = useState(null);
  const [recentPutNotifications, setRecentPutNotifications] = useState([]); // Track recent PUT notifications

  useEffect(() => {

    // Listen for the highest moonshot
    socket.on('moonshots', (moonshot) => {
      const { tokenName, priceChange } = moonshot; 
      const isSignificantChange = priceChange > 20;

      if ((!lastMoonshot && isSignificantChange) || 
          (lastMoonshot && lastMoonshot.tokenName !== tokenName && isSignificantChange) || 
          (lastMoonshot && lastMoonshot.priceChange !== priceChange && isSignificantChange)) {
      
        setNotificationMessage(`${tokenName} ${priceChange.toFixed(0)}%`);
        setShowNotification(true);

        if (!soundPlayed) {
          playSound(); 
          setSoundPlayed(true); 
        }

        setLastMoonshot({ tokenName, priceChange });
      }
    });

    // Listen for the PUT request detection
    socket.on('newDocument', (data) => {
      const putMessage = `${data.tokenName} MC $${data.priceChange.toLocaleString()}`;

      // Check if the message is in the recent notifications
      if (!recentPutNotifications.includes(putMessage)) {
        setPutNotificationMessage(putMessage);
        setShowPutNotification(true);
        
        if (!soundPlayedNew) {
          playSoundNew(); 
          setSoundPlayedNew(true); 
        }

        // Update recent notifications and keep track of the last few
        setRecentPutNotifications(prevNotifications => {
          const updatedNotifications = [...prevNotifications, putMessage];
          
          // Limit the number of tracked notifications to prevent memory bloat
          if (updatedNotifications.length > 5) {
            updatedNotifications.shift(); // Remove the oldest notification
          }

          return updatedNotifications;
        });
      }
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.off('moonshots');
      socket.off('newDocument');
    };
  }, [soundPlayed, soundPlayedNew, lastMoonshot, recentPutNotifications]);

  // Function to play sound
  const playSound = () => {
    const audio = new Audio('/notification.mp3');
    audio.play().catch((error) => console.error('Error playing sound:', error));
  };

  // Function to play sound for PUT notifications
  const playSoundNew = () => {
    const audio = new Audio('/newtoken.mp3');
    audio.play().catch((error) => console.error('Error playing sound:', error));
  };

  // Function to dismiss the moonshot notification
  const dismissNotification = () => {
    setShowNotification(false);
    setSoundPlayed(false);
  };

  // Function to dismiss the PUT request notification
  const dismissPutNotification = () => {
    setShowPutNotification(false);
    setSoundPlayedNew(false); // Reset sound played flag on dismiss
  };

  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<HomeMain />} />
            <Route path="/login" element={<LoginSignup />} />
            
            {/* Protect all other routes */}
            <Route 
              path="/alltokens" 
              element={
                <ProtectedRoute>
                  <FiveDayTokens />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/all-token-data" 
              element={
                <ProtectedRoute>
                  <AllTokenData />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/historical-tokens" 
              element={
                <ProtectedRoute>
                  <HistoryTokens />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/historical-token-data" 
              element={
                <ProtectedRoute>
                  <HistoryData />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/stored-tokens" 
              element={
                <ProtectedRoute>
                  <StoredTokens />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/stored-token-data" 
              element={
                <ProtectedRoute>
                  <StoredData />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/performance-sim" 
              element={
                <ProtectedRoute>
                  <PerformanceSim />
                </ProtectedRoute>
              } 
            />
             <Route 
              path="/performance-sim-all" 
              element={
                <ProtectedRoute>
                  <PerformanceSimAll />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/prediction" 
              element={
                <ProtectedRoute>
                  <Prediction />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/security-check" 
              element={
                <ProtectedRoute>
                  <ContractScanner />
                </ProtectedRoute>
              } 
            />
             <Route 
              path="/security-check/auto/:contractAddress" 
              element={
                <ProtectedRoute>
                  <ContractScannerAuto />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Router>
      </AuthProvider>

      {showNotification && (
        <div className='notificationmain'>
          <div className={`notification ${showNotification ? 'show' : ''}`} onClick={dismissNotification}>
          <div className='messageText'>{notificationMessage}</div> 
            <img src="/up.gif" alt="Price Change Alert" style={{ width: '100px', height: '100px' }} />
        </div>
        </div>
      )}

      {showPutNotification && (
        <div className='notificationmain'>
          <div className={`notificationPut ${showPutNotification ? 'show' : ''}`} onClick={dismissPutNotification}>
            <div className='messageText'>{putNotificationMessage}</div>
            <img src="/new.gif" alt="New Token Alert" style={{ width: '100px', height: '100px' }} />
        </div>
        </div>
      )}
    </div>
  );  
}

export default App;
