import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import './contractScanner.css';
// Importing FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShieldAlt, faChartLine, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FaTelegram, FaTwitter } from 'react-icons/fa';

const ContractScannerAuto = () => {
  const { contractAddress } = useParams(); 
  const [threats, setThreats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [holders, setHolders] = useState(null);
  const [dev, setDev] = useState(null);
  const [stability, setStability] = useState(null);
  const [error, setError] = useState("");
  const [expandedThreats, setExpandedThreats] = useState({});
  const [tokenName, setTokenName] = useState(""); // State to store tokenName
  const isLocal = window.location.hostname === 'localhost';
  const baseUrl = isLocal ? 'http://localhost:5000' : 'https://www.trendscout.club';

  useEffect(() => {
    if (contractAddress) {
      fetchContractDetails(contractAddress);
    }
  }, [contractAddress]);

  const fetchContractDetails = async (contractAddress) => {
    setError("");
    setResults(null);
    setThreats(null);
    setTokenName("");
    setHolders(null);
    setDev(null); 
    setStability(null); 
    setLoading(true);

    try {
      const response = await axios.post(`${baseUrl}/api/scan-contract/auto/${contractAddress}`);
      setResults(response.data.verificationData);
      // Ensure 'Holders' is an array before setting it to state
      const holdersData = Array.isArray(response.data.verificationData.Holders)
        ? response.data.verificationData.Holders
        : [];
      setHolders(holdersData);
      setDev(response.data.verificationData.DevDetails);
      setStability(response.data.verificationData.Stability)
      setThreats(response.data.categorizedThreats);
      setTokenName(response.data.verificationData.TokenName || "Unknown Token"); // Assuming tokenName is in the response
    } catch (err) {
      setError("Error fetching contract details. Please ensure the address is correct and try again.");
    } finally {
      setLoading(false);
    }
  };

    const toggleThreatExpansion = (level) => {
      setExpandedThreats((prev) => ({
        ...prev,
        [level]: !prev[level],
      }));
    };
  
    const renderThreats = () => {
      return Object.entries(threats).map(([level, items]) => {
        const isExpanded = expandedThreats[level];
        const hasReasons = items.some((item) => item.reasons && item.reasons.length > 0);
    
        return (
          <div key={level} className={`threat-level ${level.toLowerCase()}`}>
            <h4 onClick={() => toggleThreatExpansion(level)} className="threat-header">
              {level}
              <FontAwesomeIcon
                icon={hasReasons ? faExclamationTriangle : faShieldAlt}
                style={{
                  marginLeft: "10px",
                  color: hasReasons ? "red" : "green", // Red for threats with reasons, green otherwise
                }}
              />
              <FontAwesomeIcon
                icon={isExpanded ? faMinus : faPlus}
                style={{ marginLeft: "10px", cursor: "pointer" }}
              />
            </h4>
            {isExpanded && (
              <ul>
                {items.length < 1 ? (
                  <li>NONE</li>
                ) : (
                  items.map((item, index) => (
                    <li key={index}>
                      <strong>{item.threat}</strong>
                      {item.reasons && (
                        <ul>
                          {item.reasons.map((reason, reasonIndex) => (
                            <li key={reasonIndex}>{reason}</li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        );
      });
    };

  // Render Social Links with Icons only
  const renderSocialLinks2 = (socialLinks, contractAddress) => {
    const dextoolsLink = `https://www.dextools.io/app/en/ether/pair-explorer/${contractAddress}`; // Adjust URL if necessary
    
    return (
      <>
        {Object.entries(socialLinks).map(([platform, link]) => {
          if (link && link !== "N/A") {
            let icon;
            switch (platform) {
              case 'telegram':
                icon = <FaTelegram />;
                break;
              case 'twitter':
                icon = <FaTwitter />;
                break;
              default:
                icon = null;
            }
  
            return (
              <div key={platform} className="social-link">
                <a href={link} target="_blank" rel="noopener noreferrer" className="social-icon-link">
                  {icon}
                </a>
              </div>
            );
          }
          return null; // Skip invalid links
        })}
  
        {/* Chart Icon */}
        <div className="social-link">
          <a href={dextoolsLink} target="_blank" rel="noopener noreferrer" className="social-icon-link">
            <FontAwesomeIcon icon={faChartLine} /> {/* Chart icon */}
          </a>
        </div>
      </>
    );
  };
  

  return (
    <div className="contractScanMain">
      <div className="container">
        <h2>Ethereum Contract Scanner</h2>

        {error && <p className="error">{error}</p>}

        {/* Display the token name after scanning */}
        {tokenName && !loading && (
          <div className="tokennamecontainer">
          <p className="token-name">{tokenName}</p> 
          </div>
        )}

        {results && (results.Telegram || results.Twitter) && (
            <div className="social-links">
              {renderSocialLinks2(
                {
                  telegram: results.Telegram,
                  twitter: results.Twitter,
                },
                contractAddress // Pass the contract address for the Dextools chart link
              )}
            </div>
          )}

                  {stability && (
                      <div className="results">
                        <h3>Stability Range</h3>
                        <div className="threat-level">
                          <div className="holder-address">
                            {stability === 1000 
                              ? <strong>Token is extremely quiet 🥱💤</strong> 
                              : <span>Short term, token trading within a <strong>{stability}% range</strong></span>}
                          </div>
                        </div>
                      </div>
                    )}
                    

           {threats && (
              <div className="results">
                <h3>Threat Levels</h3>
                {renderThreats()}
              </div>
            )}

            
                {dev && (
                    <div className="results">
                      <h3>Deployer Details</h3>
                      <div className="threat-level">
                        <div className="holder-address">
                          <strong>Deployer: </strong> {dev.devAddress} - {(parseFloat(dev.devHoldings)*100).toFixed(2)}%
                        </div>
                      </div>
                    </div>
                  )}

      {holders && Array.isArray(holders) && holders.length > 0 && (
          <div className="threat-level">
            <h3>Holder Details</h3>
            {holders.map((holder, index) => {
              const percent = parseFloat(holder.percent).toFixed(2);
              const isRedFlag = parseFloat(holder.percent) > 3;

              return (
                <div key={index} className="holder-item">
                  <div className="holder-address">
                    {holder.address}
                  </div>
                  <div className="holder-percent">
                    {percent}%
                    {isRedFlag && (
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        style={{ marginLeft: '5px', color: 'red' }} // Red flag icon
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {results && (
          <div className="results">
            <h3>Raw Data</h3>
            <pre>{JSON.stringify(results, null, 2)}</pre>
          </div>
        )}

        {loading && <p>Loading...</p>}
      </div>
    </div>
  );
};

export default ContractScannerAuto;
